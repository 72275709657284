import type { Context } from '@datadog/browser-core'
import type { LogsInitConfiguration, StatusType } from '@datadog/browser-logs'
import type { RumInitConfiguration } from '@datadog/browser-rum'
import type { LocationQuery } from 'vue-router'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { logEvent as _logEvent } from '@peter-park/vue-common-utils'

export const datadogConfig: LogsInitConfiguration = {
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  sessionSampleRate: 100,
  service: 'b2b-dashboard',
  version: $APP_VERSION,
  env: import.meta.env.VITE_BUILD_STAGE,
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
}
export const datadogRumConfig: RumInitConfiguration = {
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  sessionSampleRate: 100,
  service: 'b2b-dashboard',
  env: import.meta.env.VITE_BUILD_STAGE,
  version: $APP_VERSION,
  site: 'datadoghq.eu',
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
}

export interface LoggerSuperProperties extends Context {
  user: string
}

export function setSuperProperties(superProperties: LoggerSuperProperties) {
  if (import.meta.env.PROD) {
    datadogRum.setUser({
      user: superProperties.user.toString(),
    })
    datadogRum.onReady(() => {
      datadogRum.setGlobalContext(superProperties)
    })
    datadogLogs.onReady(() => {
      datadogLogs.setGlobalContext(superProperties)
    })
  }
  else {
    // eslint-disable-next-line no-console
    console.log('setSuperProperties', superProperties)
  }
}

/**
 * Internal enum with messages to log for each event
 */
enum LoggerEventsMessages {
  authenticated = 'Authenticated',
  navigationChange = 'navigate',
  clickLogOut = 'Click LogOut',
  verifyFile = 'Verify file',
  uploadFile = 'Upload file',
  finishAccountVerification = 'Finish and close account-verification wizard',
  logChangeStep = 'Change Step',
  verificationSubmitErrorDialogClose = 'account-verification-submit-error: Close Dialog',
  closeWizard = 'Close wizard',
  downloadTemplateXlsx = 'Download account-verification template xlsx',
  downloadSampleXlsx = 'Download account-verification sample xlsx',
  openAccountVerificationWizard = 'Open account-verification wizard',
  languageSelect = 'Language Change',
  selectArea = 'Area selection',
  selectGroup = 'Group select',
  areaServiceNoteOpened = 'Area service note opened',
  areaServiceNoteClosed = 'Area service note closed',
}

function logEvent(message: LoggerEventsMessages, messageContext?: Record<string, unknown>, statusType?: StatusType, error?: Error) {
  if (import.meta.env.PROD)
    _logEvent(message, messageContext, statusType, error)
  else
    // eslint-disable-next-line no-console
    console.info(message, messageContext, statusType, error)
}

export function logAuthenticated(locationQuery: LocationQuery, username: string) {
  logEvent(LoggerEventsMessages.authenticated, { ...locationQuery, username })
}

export function logClickLogout(locationQuery: LocationQuery, username: string) {
  logEvent(LoggerEventsMessages.clickLogOut, { ...locationQuery, username })
}

export function logNavigation(to: string) {
  logEvent(LoggerEventsMessages.navigationChange, { to })
}

export function logFinishAccountVerification() {
  logEvent(LoggerEventsMessages.finishAccountVerification, {})
}

export function logVerificationSubmitErrorDialogClose() {
  logEvent(LoggerEventsMessages.verificationSubmitErrorDialogClose, {})
}

export function logChangeStep(nextStep: string, nextStepIndex: number) {
  logEvent(LoggerEventsMessages.logChangeStep, { nextStep, nextStepIndex })
}

export function logVerifyFile(areaEntityId: string, groupIds: string[], filename: string, filesize: string) {
  logEvent(LoggerEventsMessages.verifyFile, { areaEntityId, groupIds, filename, filesize })
}

export function logUploadFile(areaEntityId: string, groupIds: string[], filename: string, filesize: string) {
  logEvent(LoggerEventsMessages.uploadFile, { areaEntityId, groupIds, filename, filesize })
}

export function logCloseWizard() {
  logEvent(LoggerEventsMessages.closeWizard, {})
}

export function logDownloadAccountVerificationSampleXlsx() {
  logEvent(LoggerEventsMessages.downloadSampleXlsx, {})
}

export function logDownloadAccountVerificationTemplateXlsx() {
  logEvent(LoggerEventsMessages.downloadTemplateXlsx, {})
}

export function logOpenAccountVerificationWizard() {
  logEvent(LoggerEventsMessages.openAccountVerificationWizard, {})
}

export function logLanguageSelect(language: string) {
  logEvent(LoggerEventsMessages.languageSelect, { language })
}

export function logSelectArea(area: string) {
  logEvent(LoggerEventsMessages.selectArea, { area })
}

export function logSelectGroup(groups: string) {
  logEvent(LoggerEventsMessages.selectGroup, { groups })
}

export function logAreaServiceNoteOpened() {
  logEvent(LoggerEventsMessages.areaServiceNoteOpened, {})
}

export function logAreaServiceNoteClosed() {
  logEvent(LoggerEventsMessages.areaServiceNoteClosed, {})
}
