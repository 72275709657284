<script lang="ts" setup>
defineOptions({
  name: 'BaseDialog',
})

const { open, loading = false, buttonsAlign = 'center', size = 'sm' } = defineProps<DialogProps>()

const emits = defineEmits<{
  clickOutside: []
}>()

export interface DialogProps {
  open: boolean
  loading?: boolean
  buttonsAlign?: ButtonsAlign
  size?: 'sm' | 'md' | 'lg'
}

type ButtonsAlign = 'center' | 'left' | 'right'

const buttonsAlignToClasses = new Map([
  ['center', 'justify-center'],
  ['left', 'justify-start'],
  ['right', 'justify-end'],
])

const sizeToClasses = new Map([
  ['sm', 'w-80 md:w-100'],
  ['md', 'w-80 md:w-160'],
  ['lg', 'w-auto'],
])

function handleClickOutside() {
  emits('clickOutside')
}
</script>

<template>
  <DialogRoot
    :open="open"
  >
    <DialogPortal>
      <DialogOverlay class="fixed inset-0 z-30 bg-gray-700 opacity-50" />
      <DialogContent
        class="fixed left-1/2 top-1/2 z-100 translate-x-[-50%] translate-y-[-50%] rounded-xl bg-white py-4 md:py-6 focus:outline-none"
        :class="sizeToClasses.get(size)"
        @interact-outside="handleClickOutside"
      >
        <div class="h-full flex flex-col items-center justify-between">
          <div class="flex flex-col items-center gap-5">
            <div v-if="loading" class="i-custom:spinner h-14 w-14 animate-spin" />
            <slot v-else name="icon" />
            <div class="flex flex-col items-center gap-1 px-4 md:px-6">
              <span class="text-center text-4.5 font-semibold">
                <slot name="headline" />
              </span>
              <span class="max-h-60vh overflow-auto pb-5 text-center text-4 text-gray-600 leading-5">
                <slot name="content" />
              </span>
            </div>
          </div>
          <div v-if="!loading" class="w-full border-t-1 pt-4 md:pt-6">
            <div class="flex gap-4 px-4 md:px-6" :class="buttonsAlignToClasses.get(buttonsAlign)">
              <slot name="buttons" />
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
</template>
