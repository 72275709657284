<script lang="ts" setup>
defineOptions({
  name: 'BaseCard',
})

const { color = 'gray' } = defineProps<{
  color?: Color
}>()
type Color = 'red' | 'gray' | 'green'
const bgColorToClass = new Map<Color, string>([
  ['red', 'bg-red-50'],
  ['gray', 'bg-gray-100'],
  ['green', 'bg-green-50'],
])
const borderColorToClass = new Map<Color, string>([
  ['red', 'border-red-100'],
  ['gray', 'border-gray-200'],
  ['green', 'border-green-100'],
])
</script>

<template>
  <div class="border-1 rounded-2xl p-2" :class="[bgColorToClass.get(color), borderColorToClass.get(color)]">
    <BaseCardHeader v-if="$slots.title || $slots.subtitle">
      <template #title>
        <slot name="title" />
      </template>
      <template #subtitle>
        <slot name="subtitle" />
      </template>
      <template #actions>
        <slot name="actions" />
      </template>
    </BaseCardHeader>
    <slot name="content" />
  </div>
</template>
