<script setup lang="ts">
import { SwitchRoot, SwitchThumb } from 'radix-vue'

defineOptions({
  name: 'AccountVerificationBulkUploadWarningBox',
})

const { bulkUploadChanges, loading = false, noToggle = false } = defineProps<{
  bulkUploadChanges?: {
    deleteCount: number
    insertCount: number
    updateCount: number
  }
  loading?: boolean
  noToggle?: boolean
}>()

const bulkUploadWarningSwitch = defineModel<boolean>()
const { t } = useI18n()

const cardColor = computed(() => {
  return bulkUploadWarningSwitch.value || noToggle ? 'red' : 'gray'
})
</script>

<template>
  <BaseCard :color="cardColor" class="min-h-30">
    <template #title>
      {{ t('account_verification.overwrite_delete_existing') }}
    </template>
    <template #subtitle>
      {{ t('account_verification.overwrite_delete_existing_subtitle') }}
    </template>
    <template v-if="!noToggle" #actions>
      <SwitchRoot
        id="bulk-upload-warning-switch"
        v-model:checked="bulkUploadWarningSwitch"
        class="relative h-[25px] w-[42px] flex cursor-default rounded-full bg-gray/50 shadow-sm data-[state=checked]:bg-red-600 focus-within:outline-none"
      >
        <SwitchThumb
          class="my-auto block h-[21px] w-[21px] translate-x-0.5 rounded-full bg-white shadow-sm transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[19px]"
        />
      </SwitchRoot>
    </template>
    <template #content>
      <transition
        enter-active-class="transition duration-200 ease-out" enter-from-class="transform opacity-0"
        enter-to-class="transform opacity-100" leave-active-class="transition duration-0 ease-out"
        leave-from-class="transform opacity-100" leave-to-class="transform opacity-0"
      >
        <BaseInfoBox v-if="(noToggle || bulkUploadWarningSwitch) && !loading && bulkUploadChanges" color="red">
          <template #title>
            {{ t('account_verification.overwrite_delete_infobox_title') }}
          </template>
          <template #content>
            <ul class="list-disc text-sm">
              <li>{{ t('account_verification.this_upload') }} <span class="font-600">{{ t('account_verification.deletes_n_codes', bulkUploadChanges.deleteCount, { named: { number: bulkUploadChanges.deleteCount } }) }}</span>.</li>
              <li>{{ t('account_verification.this_upload') }} <span class="font-600">{{ t('account_verification.add_n_codes', bulkUploadChanges.insertCount, { named: { number: bulkUploadChanges.insertCount } }) }}</span>.</li>
              <li>{{ t('account_verification.the_description_is') }} <span class="font-600">{{ t('account_verification.changed_description_n_codes', bulkUploadChanges.updateCount, { named: { number: bulkUploadChanges.updateCount } }) }}</span>.</li>
            </ul>
          </template>
        </BaseInfoBox>
      </transition>
      <transition
        enter-active-class="transition duration-150 ease-out" enter-from-class="transform opacity-0"
        enter-to-class="transform opacity-100" leave-active-class="transition duration-0 ease-out"
        leave-from-class="transform opacity-100" leave-to-class="transform opacity-0"
      >
        <div v-if="loading && bulkUploadWarningSwitch" class="flex flex-wrap justify-between gap-3">
          <div
            v-for="element in 3" :key="element"
            class="h-5 w-full animate-pulse rounded bg-red-100 p-1 pt-2"
          />
        </div>
      </transition>
    </template>
  </BaseCard>
</template>
