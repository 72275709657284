<script setup lang="ts">
defineOptions({
  name: 'FormSection',
})
const props = defineProps<{
  title: string
  description?: string
}>()
</script>

<template>
  <div class="grid grid-cols-1 mb-2 gap-4 rounded-xl bg-gray-100 p-2">
    <div class="grid gap-2 p-2">
      <p class="font-medium">
        {{ props.title }}
      </p>
      <p v-if="props.description" class="text-sm text-gray-600">
        {{ props.description }}
      </p>
    </div>
    <div class="relative transition-all">
      <div class="grid gap-2">
        <slot />
      </div>
    </div>
  </div>
</template>
