<script setup lang="ts">
const { open } = defineProps<{
  open: boolean
}>()

const emits = defineEmits<{
  onCancel: []
  onConfirm: []
}>()

const { t } = useI18n()
</script>

<template>
  <Dialog :open="open" size="md">
    <template #headline>
      <div class="flex flex-col items-center gap-6 px-2 pt-2">
        <div class="i-custom:bulk-upload h-12 w-12" />
        <p>
          {{ t('account_verification.bulk_upload.confirm_upload_title') }}
        </p>
      </div>
    </template>
    <template #buttons>
      <BaseButton color="outline-gray" size="md" full @click="emits('onCancel')">
        <span class="text-base font-600 leading-6"> {{ t('account_verification.bulk_upload.cancel_upload') }}</span>
      </BaseButton>
      <BaseButton size="md" full color="danger" @click="emits('onConfirm')">
        <span class="text-base font-600 leading-6">  {{ t('account_verification.bulk_upload.confirm_upload') }}</span>
      </BaseButton>
    </template>
  </Dialog>
</template>
