<script setup lang="ts">
defineOptions({
  name: 'BaseInfoBox',
})

const { color = 'gray' } = defineProps<{
  color?: Color
}>()

type Color = 'red' | 'gray' | 'green'
const bgColorToClass = new Map<Color, string>([
  ['red', 'bg-red-25'],
  ['gray', 'bg-gray-25'],
  ['green', 'bg-green-25'],
])
const borderColorToClass = new Map<Color, string>([
  ['red', 'border-red-600'],
  ['gray', 'border-gray-600'],
  ['green', 'border-green-600'],
])
const textColorToClass = new Map<Color, string>([
  ['red', 'text-red-600'],
  ['gray', 'text-gray-600'],
  ['green', 'text-green-600'],
])
</script>

<template>
  <div class="border-1 rounded-xl p-4" :class="[bgColorToClass.get(color), borderColorToClass.get(color), textColorToClass.get(color)]">
    <div class="flex items-center gap-4">
      <div class="i-material-symbols:info-outline-rounded h-4.5 w-4.5" />
      <p class="text-sm font-600 leading-none">
        <slot name="title" />
      </p>
    </div>
    <div v-if="$slots.content" class="ml-14 mt-3">
      <slot name="content" />
    </div>
  </div>
</template>
